<template>
  <form action="/" class="form-new-task">
    <div class="coll coll-12">
      <ComponentInput
        label="Название задачи <span>*</span>"
        placeholder="Введите название"
        v-model="nameTask"
        mod="rectangular"
        required
      />
    </div>
    <div class="coll coll-12">
      <div class="label-field">Тематический блок <span>*</span></div>
      <SelectMultiComponent
        v-model="selectedThematicID"
        :options="optionsThematic"
        placeholder="Выберите из списка"
        :classSelect="{ error: errorThematic }"
        modifier="rectangular"
      />
      <p v-if="errorThematic" class="invalid-msg">Поле обязательно для заполнения</p>
    </div>
    <div class="form-new-task__country">
      <div class="form-new-task__country-title">Страны <span>*</span></div>
      <div class="form-new-task__block-interaction-country">
        <div class="form-new-task__block-added-country">
          <template v-if="listSelectedCountries.length">
            <div
              class="form-new-task__item-selected"
              v-for="(item, index) in listSelectedCountries"
              :key="index"
              :id="item.id"
              @click="onDeleteCountry(item.id)"
            >
              {{ item.text }}
              <div class="form-new-task__delete-country"></div>
            </div>
          </template>
          <div class="form-new-task__block-add-country">
            <div class="form-new-task__wrap-select">
              <SelectMultiComponent
                v-model="selectedCountryId"
                placeholder="Введите страну"
                :options="getListCountry"
                modifier="select-country"
                @select="selectCountry"
              />
            </div>
          </div>
          <div class="form-new-task__clear-item-selected">
            <div class="form-new-task__clear-text" @click="onClearCountry">Очистить список</div>
          </div>
          <p class="invalid-msg" v-if="errorListSelectedCountries">Выберите страну</p>
        </div>
      </div>
    </div>
    <div class="form-new-task__subtasks">
      <div class="form-new-task__item" v-for="(item, index) in subtasksList" :key="index" :id="index + 1">
        <div class="form-new-task__title" v-if="index === 0">Подзадачи</div>
        <div class="form-new-task__title" v-if="index != 0">
          Подзадача {{ index + 1 }}
          <span @click="onDeleteSubtasksList(index + 1)" class="form-new-task__delete-subtasks">Удалить</span>
        </div>
        <div class="coll coll-12">
          <ComponentInput
            label="Название подзадачи <span>*</span>"
            placeholder="Введите название"
            v-model="item.nameSubtask"
            mod="rectangular"
            required
          />
        </div>
        <div class="coll coll-12">
          <ComponentInput
            label="Краткое описание подзадачи <span>*</span>"
            placeholder="Введите текст описания"
            v-model="item.description"
            mod="rectangular"
            required
          />
        </div>
        <div class="coll coll-6 coll-m-12">
          <ComponentInput
            label="Вес <span>*</span>"
            placeholder="0,1-1,0"
            v-model="item.weight"
            mod="rectangular"
            type="number"
            required
          />
          <p
            class="invalid-msg"
            v-if="v$.subtasksList.$each.$response.$errors[index]?.weight[0]?.$validator === nameValid.MIN_VALUE"
          >
            Минимальное значение 0,1
          </p>
          <p
            class="invalid-msg"
            v-else-if="v$.subtasksList.$each.$response.$errors[index]?.weight[0]?.$validator === nameValid.MAX_VALUE"
          >
            Максимальное значение 1
          </p>
          <p
            class="invalid-msg"
            v-else-if="v$.subtasksList.$each.$response.$errors[index]?.weight[0]?.$validator === nameValid.MAX_LENGTH"
          >
            Максимальное количество цифр после запятой не должно превышать одного
          </p>
        </div>
        <div class="coll coll-6 coll-m-12">
          <ComponentInput label="% выполнения" :disabled="true" mod="rectangular" v-model="percent" />
        </div>
      </div>
    </div>
    <div class="coll coll-12" v-if="errorAddSubtasksList">
      <p class="invalid-msg">Пожалуйста, заполните все доступные поля в предыдущих задачах</p>
    </div>
    <div class="coll coll-12" v-else-if="errorHeavyWeight">
      <p class="invalid-msg">Сумма весов подзадач в рамках одной Сквозной задачи должна быть равна 1</p>
    </div>
    <div @click="onAddSubtasks" class="form-new-task__add-subtasks">
      <IconComponent name="plus-mini" class="form-new-task__plus" />
      Добавить подзадачу
    </div>
    <div class="form-new-task__button">
      <ButtonComponent
        @click.prevent.stop="onCreated"
        mod="gradient-bg"
        class="button-redesign--map-apply"
        :disabled="disabledButton"
        >Создать задачу</ButtonComponent
      >
      <ButtonComponent @click="onClose" :disabled="disabledButton">Отмена</ButtonComponent>
    </div>
  </form>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { helpers, maxLength, maxValue, minValue, required } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import constants from '../constants.js';

  export default {
    name: 'FormCreateNewTask',
    components: {
      SelectMultiComponent,
      ComponentInput,
      IconComponent,
      ButtonComponent,
    },
    props: {
      optionsAllCountry: {
        type: [Object, Array],
        default: () => {},
      },
      optionsThematic: {
        type: [Object, Array],
        default: () => {},
      },
      disabledButton: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedThematicID: null,
        errorThematic: false,
        selectedCountryId: null,
        nameTask: '',
        subtasksList: [
          {
            nameSubtask: '',
            description: '',
            weight: '',
            id: 1,
          },
        ],
        noValidSabTask: true,
        errorAddSubtasksList: false,
        listSelectedCountries: [],
        errorListSelectedCountries: false,
        percent: '',
        errorHeavyWeight: false,
        objSelectCountry: {
          label: '',
          value: '',
        },
        nameValid: constants.VALID_NAME,
      };
    },
    watch: {
      selectedThematicID() {
        this.errorThematic = false;
      },
      subtasksList: {
        handler(val) {
          val.forEach((i) => {
            if (!i.nameSubtask || !i.description || !i.weight) {
              this.noValidSabTask = true;
            } else {
              this.noValidSabTask = false;
            }
          });
          this.errorHeavyWeight = false;
        },
        deep: true,
      },
      objSelectCountry: {
        handler(val) {
          if (val?.label) {
            const index = this.listSelectedCountries.findIndex((i) => i.id === val.value);
            if (index < 0) {
              this.listSelectedCountries.push({
                text: val.label,
                id: val.value,
              });
            }

            this.objSelectCountry = {
              label: '',
              value: '',
            };
          }
          setTimeout(() => {
            this.selectedCountryId = null;
            this.errorListSelectedCountries = false;
          }, 300);
        },
        deep: true,
      },
    },
    computed: {
      getTotalWeight() {
        const sum = this.subtasksList.reduce((accumulator, currentValue) => accumulator + currentValue.weight, 0);

        return parseFloat(sum.toFixed(3));
      },

      getHeavyWeight() {
        return this.getTotalWeight !== 1;
      },

      getListCountry() {
        const list = this.optionsAllCountry.filter((i) => {
          const item = this.listSelectedCountries.some((j) => +i.value === +j.id);
          return item ? false : i;
        });
        return list;
      },
    },
    mounted() {
      const form = document.querySelector('.form-new-task');
      form.addEventListener('wheel', function () {
        if (document.activeElement.type === 'number') {
          document.activeElement.blur();
        }
      });
    },
    methods: {
      onAddSubtasks() {
        if (this.noValidSabTask) {
          this.errorAddSubtasksList = true;
          return;
        }
        this.errorAddSubtasksList = false;
        this.subtasksList.push({
          nameSubtask: '',
          description: '',
          weight: '',
          id: this.subtasksList.length + 1,
        });
      },

      onDeleteSubtasksList(id) {
        this.errorAddSubtasksList = false;
        this.subtasksList = this.subtasksList.filter((i) => i.id !== id);
      },

      onClearCountry() {
        this.listSelectedCountries = [];
      },

      onDeleteCountry(id) {
        this.listSelectedCountries = this.listSelectedCountries.filter((i) => i.id !== id);
      },

      selectCountry(_, obj) {
        this.objSelectCountry = obj;
      },

      // вызывается через ref
      /**@public */
      reset() {
        this.nameTask = '';
        this.selectedThematicID = '';
        this.listSelectedCountries = [];
        this.subtasksList = [
          {
            nameSubtask: '',
            description: '',
            weight: '',
            id: 1,
          },
        ];

        this.v$.$reset();
      },

      onCreated() {
        this.v$.$touch();
        if (!this.selectedThematicID) {
          this.errorThematic = true;
        }
        if (!this.listSelectedCountries.length) {
          this.errorListSelectedCountries = true;
        }
        if (this.v$.$invalid || this.getHeavyWeight) {
          if (this.getHeavyWeight) {
            this.errorHeavyWeight = true;
          }
          return;
        }
        const dataForm = {
          name: this.nameTask,
          thematicBlock: { id: this.selectedThematicID },
          countries: this.listSelectedCountries,
          subtasks: this.subtasksList.map((i) => ({
            name: i.nameSubtask,
            weight: +i.weight,
            description: i.description,
          })),
        };

        this.$emit('onCreated', dataForm);
      },

      onClose() {
        this.$emit('close');
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations: {
      nameTask: { required },
      selectedThematicID: { required },
      listSelectedCountries: { required },
      subtasksList: {
        $each: helpers.forEach({
          nameSubtask: { required },
          description: { required },
          weight: {
            required,
            minValue: minValue(0.1),
            maxValue: maxValue(1),
            maxLength: maxLength(3),
          },
        }),
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../styles/coll.scss';
  .form-new-task {
    margin: 0 -8px;
    &__item {
      display: flex;
      flex-wrap: wrap;
    }

    &__title {
      margin: 0 8px 24px;
      font: $fira-20-M;
    }

    &__add-subtasks {
      color: $green;
      font: 15px / 20px 'Fira Sans';
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s ease;
      margin: 0 8px;

      &:hover {
        opacity: 0.3;
      }
    }

    &__plus {
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }

    &__button {
      display: flex;
      margin: 40px 8px 0;
    }

    &__wrap-select {
      width: 126px;
    }

    &__block-add-country {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    &__item-selected {
      padding: 8px 12px;
      border-radius: 12px;
      background: $light-grey;
      font: $fira-16;
      color: #000;
      margin-right: 8px;
      margin-bottom: 8px;
      position: relative;
      cursor: pointer;

      &::before {
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3e%3crect width='12' height='12' rx='6' fill='%23DC3545'/%3e%3cpath d='M3 6H9' stroke='white'/%3e%3c/svg%3e");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        top: 0;
        left: 0;
        margin: -3px 0 0 -1px;
        opacity: 0;
        transition: 0.3s ease;
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }

    &__block-added-country {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
    }

    &__block-interaction-country {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__country {
      margin: 0 8px 40px;

      &-title {
        font-family: 'Fira Sans';
        margin-bottom: 8px;

        span {
          color: $red;
        }
      }
    }

    &__clear {
      &-item-selected {
        width: 100%;
      }

      &-text {
        font: 15px / 20px 'Fira Sans';
        color: $dark-grey-1;
        position: relative;
        padding-left: 20px;
        cursor: pointer;
        display: inline-block;
        transition: 0.3s ease;

        &:hover {
          opacity: 0.8;
        }

        &::before {
          position: absolute;
          content: '';
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3e%3cpath d='M3.33301 8H12.6663' stroke='%23989898' stroke-width='1.5' stroke-linecap='square' stroke-linejoin='round'/%3e%3c/svg%3e");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 16px;
          height: 16px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &__delete-subtasks {
      display: inline-block;
      font: $fira-14;
      color: red;
      margin-left: 10px;
      cursor: pointer;
      transition: 0.3s ease;

      &:hover {
        opacity: 0.8;
      }
    }

    @media (max-width: $laptop) {
      &__country {
        margin-bottom: 24px;
      }

      &__title {
        margin-bottom: 16px;
      }

      &__button {
        margin-top: 24px;
      }
    }
  }
</style>

export default {
  methods: {
    hiddenBodyScroll() {
      document.body.classList.add('hidden-scroll');
    },

    showBodyScroll() {
      document.body.classList.remove('hidden-scroll');
    },
  },
};

<template>
  <MapPopup @closePopup="close">
    <template v-slot:header>
      <div class="popup-map__header-left" id="popup_country_name${id}">
        <div @click="nextPageCrossCuttingTask" class="popup-map__name">{{ countryName }}</div>
        <div class="popup-map__all-tasks">
          Всего Сквозных задач: <span>{{ countTasks }}</span>
        </div>
      </div>
      <div class="popup-map__header-right">
        <img :src="image" alt="logo-country" width="125" height="84" />
      </div>
    </template>
    <template v-slot:body>
      <p v-for="(item, index) in tasks" :key="index" :id="item.id">
        <span @click="nextPageCrossCuttingTaskDetail(item.id)">{{ item.name }}</span>
      </p>
    </template>
  </MapPopup>
</template>

<script>
  import MapPopup from '@/common/components/redesigned/popups/MapPopup.vue';

  export default {
    name: 'PopupLeaflet',
    components: { MapPopup },
    props: {
      countryName: {
        type: String,
        default: '',
      },
      countTasks: {
        type: String || Number,
        default: '',
      },
      image: {
        type: String,
        default: '',
      },
      id: {
        type: String || Number,
        default: '',
      },
      tasks: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {};
    },
    methods: {
      close() {
        this.$emit('close');
      },

      nextPageCrossCuttingTask() {
        this.$router.push({ name: 'cross-cutting-task', params: { id: this.id } });
      },

      nextPageCrossCuttingTaskDetail(id) {
        this.$router.push({ name: 'cross-cutting-task-card', params: { id: this.id, task: id } });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .popup-map {
    &__header {
      &-right {
        width: 125px;
        height: 84px;
        vertical-align: middle;

        img {
          width: 100%;
          max-height: 100%;
          object-fit: contain;
          height: 100%;
          object-position: left;
        }
      }

      &-left {
        width: calc(100% - 125px);
        padding-right: 5px;
      }
    }

    &__name {
      margin-bottom: 4px;
      color: #000;
      font: 600 14px / 20px 'Fira Sans';
      cursor: pointer;
      transition: 0.3s ease;

      &:hover {
        opacity: 0.8;
      }
    }

    &__all-tasks {
      color: #000;
      font: 14px / 20px 'Fira Sans';
      padding-right: 30px;

      span {
        font-weight: 600;
      }
    }

    p {
      margin: 0 0 12px;
      font: $fira-12;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        font: inherit;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media (max-width: $laptop) {
      &__header {
        &-right {
          width: 192px;
          height: 99px;
          margin-bottom: 24px;
        }

        &-left {
          width: 100%;
        }
      }

      &__name {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 8px;
      }

      &__close {
        top: 30px;
        right: 20px;
        width: 14px;
        height: 14px;
      }
    }
  }
</style>

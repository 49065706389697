<template>
  <Loader v-if="!hiddenLoader" class="loader-cross-cutting-tasks" />
  <template v-else>
    <PageTitle title="Мониторинг реализации Сквозных задач МПК" :classTitleBlock="{ 'm-0': !visibleInputActual }">
      <template v-slot:subtitles>
        <li>
          Всего Сквозных задач<span>{{ allTasks }}</span>
        </li>
        <li>
          Всего стран<span>{{ allCountries }}</span>
        </li>
        <li>
          Всего тематических блоков<span>{{ allThematicBlocks }}</span>
        </li>
      </template>
      <template v-slot:button>
        <ButtonComponent v-if="visibleCreateButton" @click="onOpenCreateTaskPopup" mod="gradient-bg"
          >Создать сквозную задачу</ButtonComponent
        >
      </template>
    </PageTitle>
    <div class="container-page big container-page--large-indentation">
      <div class="map-cross-cutting-tasks-page">
        <div class="block-choice">
          <div class="block-choice__select-wrap">
            <div class="block-choice__select">
              <div class="label-field">Страна</div>
              <SelectMultiComponent
                :options="optionsAllCountries"
                placeholder="Все страны"
                classSelect="multiselect--rectangular"
                v-model="selectedCountriesID"
              />
            </div>
            <div class="block-choice__select">
              <div class="label-field">Тематический блок</div>
              <SelectMultiComponent
                :options="optionsAllThematic"
                placeholder="Все"
                classSelect="multiselect--rectangular"
                v-model="selectedThematicID"
              />
            </div>
            <div class="block-choice__select">
              <div class="label-field">KPI</div>
              <SelectMultiComponent
                :options="optionsAllKPI"
                placeholder="Все"
                classSelect="multiselect--rectangular"
                v-model="selectedKPIID"
                @select="onSelectAllKPI"
              />
            </div>
          </div>
          <div class="block-choice__button-wrap">
            <ButtonComponent @click="onApplyFilter" mod="gradient-bg" class="button-redesign--cross-cutting-taskfilter"
              >Применить</ButtonComponent
            >
            <ButtonComponent @click="onClearFilter" class="button-redesign--cross-cutting-taskfilter"
              >Сбросить</ButtonComponent
            >
            <ButtonComponent
              v-if="visibleNextPageCrossCuttingTask"
              @click="nextPageCrossCuttingTask"
              mod="gradient-bg"
              class="button-redesign--cross-cutting-taskfilter right"
              >Перейти к выбранной стране</ButtonComponent
            >
          </div>
        </div>
        <div class="map-block">
          <div class="map-block__wrap-map" v-click-outside="closeModalMapInfo">
            <Transition>
              <PopupLeaflet
                :countryName="popup.countryName"
                :id="popup.id"
                :image="popup.image"
                :countTasks="popup.countTasks"
                :tasks="popup.tasks"
                v-if="openModalMapInfo"
                @close="closeModalMapInfo"
              />
            </Transition>
            <div class="map-block__map" id="mapCountriesBlock"></div>
          </div>
          <MapScaleQuantity name="Количество Сквозных задач" averageCount="8" :totalCount="maxCrossCuttingTasks" />
        </div>
      </div>

      <ModalComponent
        title="Новая Сквозная задача"
        content-class="modal--new"
        width="1064"
        :themeLigth="true"
        v-model="createPopupTasks"
        @close="closePopupTasks"
        @update:modelValue="closePopupTasks"
      >
        <FormCreateNewTask
          :optionsAllCountry="countriesOption"
          :optionsThematic="optionsAllThematic"
          ref="closePopupTasks"
          @close="closePopupTasks"
          @onCreated="onCreatedCrossCuttingTask"
          :disabledButton="disabledButtonPopupTasks"
        />
      </ModalComponent>
    </div>
  </template>
</template>

<script>
  import L from 'leaflet';

  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import MapScaleQuantity from '@/common/components/redesigned/MapScaleQuantity.vue';
  import PageTitle from '@/common/components/redesigned/PageTitle.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';
  import worldMap from '@/common/custom.geo.json';
  import security from '@/common/mixins/security';
  import showHideScroll from '@/common/mixins/showHideScroll';
  import Utils from '@/common/utils';

  import Loader from '@/components/Loader.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import FormCreateNewTask from '@/modules/crossCuttingTask/components/FormCreateNewTask';
  import PopupLeaflet from '@/modules/crossCuttingTask/components/PopupLeaflet';

  import api from '../api.js';
  import constants from '../constants.js';

  export default {
    name: 'PageMonitoringCrossCuttingTasks',
    components: {
      ButtonComponent,
      SelectMultiComponent,
      ModalComponent,
      FormCreateNewTask,
      PopupLeaflet,
      Loader,
      PageTitle,
      MapScaleQuantity,
    },
    mixins: [showHideScroll, security],
    data() {
      return {
        visibleCreateButton: false,
        createPopupTasks: false,
        selectedCountriesID: null,
        selectedThematicID: null,
        selectedKPIID: null,
        KpiFrom: null,
        KpiTo: null,
        allTasks: 0,
        allCountries: 0,
        allThematicBlocks: 0,
        optionsAllCountries: [],
        optionsAllThematic: [],
        optionsAllKPI: constants.KPI,
        map: null,
        countries: [],
        countriesOption: [],
        countryNames: [],
        center: [40, 0],
        world: worldMap,
        geoJsonLayer: null,
        openModalMapInfo: false,
        popup: {
          countryName: '',
          countTasks: '',
          image: '',
          id: '',
          tasks: [],
        },
        listCountriesHeatMap: [],
        visibleNextPageCrossCuttingTask: false,
        maxCrossCuttingTasks: 0,
        disabledButtonPopupTasks: false,
        initialDownload: {
          countryListWithData: false,
          countryList: false,
          countriesInTasks: false,
          thematicBlocks: false,
          searchCrosscuttIngTask: false,
        },
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.AccessCrossCuttingTask).then((response) => {
        if (response.data) {
          Utils.searchNsi('countryListWithData', {}).then((response) => {
            this.countries = response.data.map((c) => ({
              id: c.id,
              text: c.name,
              fullName: c.nameFull || c.name,
              code: c.code,
              groupId: c.countryGroupId,
            }));

            this.currentCountry = this.countries[0];

            this.countryNames = [
              ...response.data.map((item) => {
                return {
                  nameFull: item.nameFull || item.name,
                  id: item.id,
                  code: item.code,
                };
              }),
            ];

            this.initialDownload.countryListWithData = true;
          });

          Utils.searchNsi('countryList', {}).then((response) => {
            this.countriesOption = response.data.map((c) => ({
              value: c.id,
              label: c.text,
            }));

            this.initialDownload.countryList = true;
          });

          this.onApplyFilter(true);

          api.getCountriesInTasks().then((response) => {
            if (response?.data.length) {
              this.optionsAllCountries = response.data.map((i) => ({
                value: i.id,
                label: i.name,
              }));
            }

            this.initialDownload.countriesInTasks = true;
          });

          api.getThematicBlocks().then((response) => {
            if (response?.data.length) {
              this.optionsAllThematic = response.data.map((i) => ({
                value: i.id,
                label: i.name,
              }));
            }

            this.initialDownload.thematicBlocks = true;
          });
        } else this.notAccess();
      });
      this.checkPermission(Constants.Permissions.AccessToCreatingCrossCuttingTask).then((response) => {
        if (response.data) {
          this.visibleCreateButton = true;
        }
      });
    },
    computed: {
      hiddenLoader() {
        return (
          this.initialDownload.countryListWithData &&
          this.initialDownload.countryList &&
          this.initialDownload.countriesInTasks &&
          this.initialDownload.thematicBlocks &&
          this.initialDownload.searchCrosscuttIngTask
        );
      },
    },
    methods: {
      onOpenCreateTaskPopup() {
        this.createPopupTasks = true;
        this.hiddenBodyScroll();
      },
      closeModalMapInfo() {
        this.openModalMapInfo = false;
      },
      onSelectAllKPI(_, obj) {
        const regExp = /%/g;
        const [min, max] = obj.label.split('-');
        this.KpiFrom = +min;
        this.KpiTo = +max.replace(regExp, '');
      },
      onApplyFilter(initial) {
        const params = {
          CountryId: this.selectedCountriesID,
          ThematicBlockId: this.selectedThematicID,
          KpiFrom: this.KpiFrom,
          KpiTo: this.KpiTo,
        };

        api.getSearchCrosscuttIngTask(params).then((res) => {
          const {
            countryCountTasks = [],
            totalCrossCuttingTasks = 0,
            totalThematicBlocks = 0,
            maxCrossCuttingTasks = 0,
          } = res.data;
          this.allTasks = totalCrossCuttingTasks;
          this.allCountries = countryCountTasks.length;
          this.allThematicBlocks = totalThematicBlocks;
          this.listCountriesHeatMap = countryCountTasks;
          this.maxCrossCuttingTasks = maxCrossCuttingTasks;
          if (this.selectedCountriesID) {
            this.visibleNextPageCrossCuttingTask = true;
          }

          if (initial) {
            this.initialDownload.searchCrosscuttIngTask = true;
          }
        });
      },
      onClearFilter() {
        this.selectedCountriesID = null;
        this.selectedThematicID = null;
        this.selectedKPIID = null;
        this.KpiFrom = null;
        this.KpiTo = null;
        this.visibleNextPageCrossCuttingTask = false;
        this.onApplyFilter();
      },
      onCreatedCrossCuttingTask(data) {
        this.disabledButtonPopupTasks = true;
        api
          .postCreateCrossCuttingTask(data)
          .then(() => {
            this.onApplyFilter();
            this.closePopupTasks();
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.disabledButtonPopupTasks = false;
          });
      },
      closePopupTasks() {
        this.createPopupTasks = false;
        this.$refs.closePopupTasks.reset();
        this.showBodyScroll();
      },
      nextPageCrossCuttingTask() {
        this.$router.push({ name: 'cross-cutting-task', params: { id: this.selectedCountriesID } });
      },

      // методы по работе с картой
      onDrawingMap() {
        let mapContainer = window.document.getElementById('mapCountriesBlock');
        while (mapContainer.firstChild) {
          mapContainer.removeChild(mapContainer.firstChild);
        }
        if (this.map) {
          this.map.remove();
          this.map = null;
        }

        this.map = L.map('mapCountriesBlock').setView(this.center, 1);

        L.tileLayer('', {
          maxZoom: 5,
          minZoom: 1.4,
        }).addTo(this.map);

        this.geoJsonLayer = L.geoJSON(this.world, {
          weight: 0.5,
          fillOpacity: 1,
        }).addTo(this.map);

        this.restyleGeoJsonLayer();
      },
      restyleGeoJsonLayer() {
        if (this.geoJsonLayer) {
          this.geoJsonLayer.eachLayer(this.restyleLayer);
        }
      },
      restyleLayer(layer) {
        let style = this.styleMap(layer.feature);
        layer.setStyle(style);
        this.onEachFeature(layer.feature, layer);
      },
      // Открытие инфо папапа по стране
      onEachFeature(feature, layer) {
        const self = this;
        if (feature.properties && feature.properties.name) {
          layer.on('mouseup', () => {
            const country = self.listCountriesHeatMap.find((c) => +c.code === +feature.properties.iso_n3);

            if (country != null) {
              self.popup.countryName =
                self.countryNames.find((c) => c.code === Number(feature.properties.iso_n3))?.nameFull ??
                feature?.properties?.name;

              const index = this.listCountriesHeatMap.findIndex((i) => +i.code === +country.code);

              if (index !== -1) {
                const { picture = '', taskShorts = [] } = this.listCountriesHeatMap[index];
                self.popup.id = country.id;
                self.popup.image = picture;
                self.popup.tasks = taskShorts;
                self.popup.countTasks = taskShorts.length;
              }

              self.openModalMapInfo = true;
            } else {
              self.openModalMapInfo = false;
            }
          });
        }
        layer.on('mouseover', function () {
          this.unbindTooltip();
          const country = self.countries.find((c) => c.code === Number(feature.properties.iso_n3));
          if (country) this.bindTooltip(country?.fullName ?? '').openTooltip();
        });
        layer.on('mouseout', function () {
          this.unbindTooltip();
        });
      },
      styleMap(feature) {
        const colors = constants.COLORS;
        let color = '#E3E3E3';
        const code = feature.properties.iso_n3;
        let projectsCount = 0;
        const index = this.listCountriesHeatMap.findIndex((i) => +i.code === +code);
        if (index !== -1) {
          projectsCount = this.listCountriesHeatMap[index].taskShorts.length;
        }
        feature.properties.countryStatus = projectsCount;
        const country = this.countries.find((x) => +x.code === +code);
        if (country) {
          feature.properties.rusName = country.name;
        }

        colors.forEach((i, index) => (+index === +projectsCount ? (color = i) : false));

        if (projectsCount !== 0 && projectsCount >= this.maxCrossCuttingTasks) {
          color = '#00386B';
        }

        return { color: '#60a7ee', fillColor: color, weight: 1, opacity: 1 };
      },
    },
    watch: {
      listCountriesHeatMap: {
        handler() {
          this.restyleGeoJsonLayer();
        },
      },

      hiddenLoader(val) {
        if (val) {
          setTimeout(() => this.onDrawingMap(), 1000);
        }
      },
    },
  };
</script>

<style lang="scss">
  @import '@/assets/theme/default/title.scss';

  .map-cross-cutting-tasks-page {
    .leaflet-interactive {
      outline: none;
    }

    @media (max-width: $laptop) {
      margin-top: 16px;

      &__all-info-countrys {
        flex-wrap: wrap;
        margin-bottom: 16px;
      }

      &__item {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .block-choice {
    margin-bottom: 40px;

    &__select {
      width: calc((100% / 3) - 16px);
      margin: 0 8px;

      &-wrap {
        margin: 0 -8px 24px;
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__button-wrap {
      display: flex;
      flex-wrap: wrap;
    }

    @media (max-width: $laptop) {
      margin-bottom: 24px;

      &__select {
        width: 100%;
        margin: 0 0 8px;

        &:last-child {
          margin-bottom: 0;
        }

        &-wrap {
          margin: 0 0 16px;
        }
      }
    }
  }

  .map-block {
    &__map {
      height: 524px;
      margin-bottom: 24px;
      background: #fff;
    }

    &__wrap-map {
      position: relative;
      z-index: 0;
    }

    .leaflet-popup-tip-container {
      display: none;
    }

    @media (max-width: $laptop) {
      &__map {
        height: 334px;
        margin: 0 -14px 16px;
      }

      &__wrap-map {
        position: unset;
      }
    }
  }

  .loader-cross-cutting-tasks {
    width: 100%;
    display: flex;
    justify-content: center;
  }
</style>
